@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Michroma&family=Open+Sans&display=swap");

/* Maintains header, body, footer structure */
body {
  background-color: #101010;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: #343d52;
}

/* No base padding or margin on any elements */
* {
  margin: 0;
  padding: 0;
}

/* Makes rem measurements easier to use */
html {
  font-size: 62.5%;
}

/* Page Sections */
div.section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

/* Small Screens (Mobile as Default (281-600px) */

h1.section-header {
  color: white;
  font-family: "Michroma", sans-serif;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 4rem;
}

/* Header Section */
header {
  display: flex;
  top: 0;
  width: 100%;
  align-items: center;
  padding: 2rem 0;
  justify-content: space-between;
}

img#header-logo {
  height: 3rem;
  width: 3rem;
  margin: 0 2rem;
}

img#header-logo:hover {
  cursor: pointer;
}

ul#nav-links {
  display: flex;
  flex-direction: column;
  margin-top: 16rem;
  margin-left: 2rem;
  list-style-type: none;
}

li.nav-link-item {
  color: white;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  cursor: pointer;
  font-size: 2rem;
  margin: 1rem 0;
  width: fit-content;
}

li.nav-link-item:hover {
  text-decoration: underline;
}

nav#mobile-navigation .burger-menu {
  position: absolute;
  top: 2rem;
  left: 1rem;
  cursor: pointer;
}

div#burger-opacity-background {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 1;
  backdrop-filter: blur(8px);
}

div#burger-sidebar {
  position: fixed;
  width: 80%;
  top: 0;
  bottom: 0;
  background: rgba(26, 30, 41);
  overflow: scroll;
}

nav#navigation {
  display: none;
}

/* Footer Section */
footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #263238;
  padding: 2rem 0;
  bottom: 0;
  margin-top: 4rem;
  min-height: 75vh;
}

div#name-contact-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

div#logo-name-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

img#footer-logo {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
}

p#footer-name {
  color: white;
  font-family: "Michroma", sans-serif;
  font-size: 2rem;
}

div#contact-links-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  width: fit-content;
  justify-content: center;
}

div#contact-links-container a {
  display: flex;
  margin: 1rem 0;
  align-items: center;
  color: white;
  text-decoration: none;
  font-family: "Open-Sans", sans-serif;
  font-size: 1.3rem;
  width: fit-content;
  justify-content: center;
}

div#contact-links-container a:hover {
  text-decoration: underline;
}

footer svg {
  color: white;
  margin-right: 1rem;
}

p#copyright {
  color: white;
  font-family: "Open-Sans", sans-serif;
  text-align: center;
  font-size: 1.3rem;
}

/* Button To Top Section */
#button-to-top {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 2rem 1rem;
  color: #707a91;
}

#button-to-top-arrow {
  position: fixed;
  bottom: 0;
  right: 0;
  color: white;
  margin: 3rem 2rem;
}

/* About Section */
div#about {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1#about-name {
  color: white;
  font-family: "Michroma", sans-serif;
  text-align: center;
  font-size: 5rem;
}

p#about-description {
  color: white;
  font-family: "Michroma", sans-serif;
  text-align: center;
  font-size: 1.3rem;
}

/* Project Section  */
div#project-cards {
  display: block;
}

div#project-card-row {
  display: flex;
  flex-direction: column;
}

div#project-card {
  display: flex;
  flex-direction: column;
  border-radius: 5%;
  background: #263238;
  padding: 2rem 0;
  width: 80%;
  margin: 4rem auto;
}

div#project-card-header {
  text-align: center;
}

h1#project-card-title {
  color: white;
  font-family: "Michroma", sans-serif;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

h2#project-card-subtitle {
  color: white;
  font-family: "Michroma", sans-serif;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

p#project-card-date {
  color: white;
  font-family: "Michroma", sans-serif;
  font-size: 1.25rem;
}

h3#project-card-job-title {
  color: white;
  font-family: "Michroma", sans-serif;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

div#project-card-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

img#project-card-img {
  width: 80%;
  height: auto;
  margin-bottom: 2rem;
}

p#project-card-description {
  width: 80%;
  text-align: center;
  color: white;
  font-family: "Open-Sans", sans-serif;
  font-size: 1.25rem;
}

ul#project-card-links {
  display: flex;
  margin: 0 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  list-style-type: none;
  align-items: center;
  height: 4rem;
}

ul#project-card-links svg {
  color: #84685a;
  margin-right: 0.5rem;
}

ul#project-card-links a {
  display: flex;
  color: #84685a;
  text-decoration: none;
  font-family: "Open-Sans", sans-serif;
  font-size: 1.25rem;
  align-items: center;
  margin-right: 1rem;
}

ul#project-card-links a:hover {
  text-decoration: underline;
}

ul#project-card-links p {
  display: flex;
  color: #84685a;
  font-family: "Open-Sans", sans-serif;
  font-size: 1.25rem;
  align-items: center;
}

@media only screen and (max-width: 280px) {
  /* Very Small Screens */
}

@media only screen and (min-width: 600px) and (min-height: 600px) {
  /* Larger Screens */

  h1.section-header {
    font-size: 6.5rem;
  }

  /* Header Section */
  header {
    position: sticky;
    backdrop-filter: blur(8px);
    z-index: 1;
  }

  img#header-logo {
    height: 7rem;
    width: 7rem;
  }

  ul#nav-links {
    flex-direction: row;
    margin: auto;
    margin-right: 1rem;
  }

  li.nav-link-item {
    margin: 0 2rem;
    font-size: 2rem;
  }

  nav#navigation {
    display: flex;
  }

  nav#mobile-navigation {
    display: none;
  }

  /* Footer Section */
  footer {
    min-height: auto;
  }

  div#name-contact-container {
    flex-direction: row;
    flex: 0;
    margin-bottom: 2rem;
  }

  div#logo-name-container {
    flex: 1;
    justify-content: right;
    margin-right: 3rem;
  }

  div#contact-links-container {
    flex: 1;
    justify-content: left;
    margin-left: 3rem;
  }

  p#footer-name {
    font-size: 2rem;
  }

  /* Button To Top Section */
  div#button-to-top-container {
    display: none;
  }

  /* About Section */
  h1#about-name {
    font-size: 7.5rem;
  }

  p#about-description {
    font-size: 2rem;
  }

  /* Project Section */
  div#project-card {
    width: 65%;
    margin: 4rem auto;
  }

  h1#project-card-title {
    font-size: 2rem;
  }

  h2#project-card-subtitle {
    font-size: 2rem;
  }

  p#project-card-date {
    font-size: 1.5rem;
  }

  h3#project-card-job-title {
    font-size: 1.5rem;
  }

  p#project-card-description {
    font-size: 1.25rem;
  }

  ul#project-card-links a {
    font-size: 1.25rem;
  }

  ul#project-card-links p {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1281px) {
  /* Extra Large Screens */

  h1.section-header {
    font-size: 7rem;
  }

  /* About Section */
  h1#about-name {
    font-size: 9rem;
  }

  p#about-description {
    font-size: 2rem;
  }

  /* Project Section */
  div#project-card-row {
    display: flex;
    flex-direction: row;
  }

  div#project-card {
    width: 27%;
    margin: 4rem auto;
  }
}
